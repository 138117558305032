import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "50",
  height: "51",
  viewBox: "0 0 50 51",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#F06F02",
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M11.0737 15.2372V22.1848H15.898V15.3541C15.898 10.3318 19.9668 6.25206 25 6.25206C30.0336 6.25206 34.102 10.3205 34.102 15.3541V22.1848H38.9263V15.2372C38.9263 7.54095 32.6962 1.31091 25 1.31091H24.5049V1.31953C17.0384 1.57988 11.0737 7.70666 11.0737 15.2372Z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#212529",
      d: "M5.14062 22.1849V49.5616H44.8591V22.1849H5.14062ZM27.7645 37.4513V43.3545H22.2353V37.428C21.043 36.5746 20.2481 35.1953 20.2481 33.6172C20.2481 30.9987 22.3756 28.8713 24.994 28.8713C27.6125 28.8713 29.74 30.9987 29.74 33.6172C29.74 35.207 28.9568 36.598 27.7528 37.4513H27.7645Z"
    }, null, -1)
  ])))
}
export default { render: render }